/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function Footer() {
  return (
    <div className='bg-black text-white'>
      <div className='lg:px-[8.13rem] px-[1rem]'>
        <div className='flex flex-wrap gap-y-10 justify-between py-[4.44rem]'>
          <div className='lg:w-[33.3%]'>
            <svg className='mb-[3rem]' width="123" height="26" viewBox="0 0 123 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M113.191 7.9734H114.826V26H113.191V7.9734Z" fill="white" />
              <path d="M105.018 0.866661C105.018 0.388017 105.42 0 105.917 0H122.101C122.597 0 123 0.388018 123 0.866663V9.0133C123 9.49195 122.597 9.87996 122.101 9.87996H105.917C105.42 9.87996 105.018 9.49195 105.018 9.0133V0.866661Z" fill="#19F256" />
              <path d="M27.1165 21.1996H27.5315V25.9996H27.1165V21.1996Z" fill="white" />
              <path d="M25.8701 20.7999H28.9133V22.6239H25.8701V20.7999Z" fill="#19F256" />
              <path d="M9.81842 1.03999H15.5908V25.9999H9.81842V16.2759H5.77237V25.9999H0V1.03999H5.77237V10.7639H9.81842V1.03999Z" fill="white" />
              <path d="M29.9463 1.03999L37.391 25.9999H31.4028L27.0331 11.4399L22.6634 25.9999H16.6752L24.1739 1.03999H29.9463Z" fill="white" />
              <path d="M52.8625 1.03999H58.5809V25.9999H52.8086V13.5199L49.7875 20.1239H47.252L44.2309 13.5199V25.9999H38.4586V1.03999H44.2309L48.5467 10.3999L52.8625 1.03999Z" fill="white" />
              <path d="M75.1474 1.03999H80.8658V25.9999H75.0935V13.5199L72.0724 20.1239H69.5369L66.5158 13.5199V25.9999H60.7435V1.03999H66.5158L70.8316 10.3999L75.1474 1.03999Z" fill="white" />
              <path d="M95.2205 1.03999L102.665 25.9999H96.677L92.3073 11.4399L87.9376 25.9999H81.9494L89.4481 1.03999H95.2205Z" fill="white" />
            </svg>

            {/* <img className='w-[7.25rem] mb-[1.27rem]' src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png" alt="" /> */}
            <p className='font-plus  text-[0.9375rem] font-normal'>Hamma isn't just another online trading platform – it’s designed for Gen Z and Millennials who wants to actually make money actively trading the Nigerian stock market.</p>
          </div>
          {/* <div>
            <h1 className='font-plus text-[1.25rem] font-bold  lg:mb-[2.78rem] mb-[1rem]'>HELP</h1>
            <div className='flex flex-col'>
              <a href='#hiw' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]'>How it Works</a>
              <a href='https://www.ft9ja.com/terms' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Terms & Condition</a>
              <a href='#faq' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]'>FAQ</a>
            </div>
          </div> */}
          {/* <div>
            <h1 className='font-plus text-[1.25rem] font-bold  lg:mb-[2.78rem] mb-[1rem]'>COMPANY</h1>
            <div className='flex flex-col'>
              <a href='https://ft9ja.zohorecruit.com/jobs/Careers' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Careers</a>
              <a href='https://www.community.ft9ja.com/' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Blogs</a>
            </div>
          </div> */}
          <div className='w-[33.3%] lg:w-[25%]'>
            <h1 className='font-plus text-[1.25rem] font-bold  lg:mb-[2.78rem] mb-[1rem]'>COMPANY</h1>
            <div className='flex flex-col'>
              <a href='/terms' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Terms & Condition</a>
              <a href='/policy' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Privacy Policy</a>
              <a href='/rules' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Rules</a>
            </div>
          </div>
          <div className='w-[33.3%] lg:w-[25%]'>
            <h1 className='font-plus text-[1.25rem] font-bold  lg:mb-[2.78rem] mb-[1rem]'>SOCIALS</h1>
            <div className='flex flex-col'>
              <a href='https://www.instagram.com/hammaHQ' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Instagram</a>
              <a href='https://www.facebook.com/gohammahq' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Facebook</a>
              <a href='https://www.twitter.com/hammaHQ' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Twitter</a>
              <a href='https://www.youtube.com/@hammaHQ' target='_blank' className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]' rel="noreferrer">Youtube</a>
            </div>
          </div>
          <div>
            <h1 className='font-plus text-[1.25rem] font-bold  lg:mb-[2.78rem] mb-[1rem]'>CONTACT</h1>
            <div className='flex flex-col'>
              {/* <p className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]'>
                <a href="https://api.whatsapp.com/send?phone=2348138462394">
                  +2348138462394
                </a>
              </p> */}
              <p className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]'>
                <a href="mailto:traders@gohamma.com">
                  traders@gohamma.com
                </a>
              </p>
              {/* <p className='font-plus text-[0.875rem]  font-normal mb-[1.52rem]'>Mon to Fri - 9am to 5pm WAT</p> */}
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center gap-[0.31rem] pb-[1.31rem]'>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
            <path d="M11.0002 1.85596C16.0602 1.85596 20.1668 6.01354 20.1668 11.1363C20.1668 16.259 16.0602 20.4166 11.0002 20.4166C5.94016 20.4166 1.8335 16.259 1.8335 11.1363C1.8335 6.01354 5.94016 1.85596 11.0002 1.85596ZM11.0002 3.71202C6.9485 3.71202 3.66683 7.03437 3.66683 11.1363C3.66683 15.2382 6.9485 18.5605 11.0002 18.5605C15.0518 18.5605 18.3335 15.2382 18.3335 11.1363C18.3335 7.03437 15.0518 3.71202 11.0002 3.71202ZM11.0002 6.49612C12.6685 6.49612 14.1287 7.39909 14.9308 8.74938L13.3597 9.70432C13.1124 9.28693 12.7614 8.94246 12.3416 8.70524C11.9218 8.46803 11.448 8.34637 10.9673 8.35239C10.4866 8.35841 10.0158 8.49191 9.60197 8.73956C9.18812 8.98721 8.84563 9.34037 8.60865 9.76383C8.37166 10.1873 8.24846 10.6662 8.25134 11.1529C8.25421 11.6396 8.38306 12.117 8.62503 12.5376C8.867 12.9581 9.21364 13.3071 9.63039 13.5498C10.0471 13.7924 10.5194 13.9202 11.0002 13.9204C11.4751 13.9207 11.942 13.7964 12.3554 13.5596C12.7688 13.3227 13.1145 12.9815 13.3587 12.5692L14.9308 13.5232C14.4157 14.3942 13.6325 15.0702 12.7014 15.4473C11.7704 15.8244 10.7428 15.8819 9.77632 15.611C8.80986 15.34 7.95788 14.7556 7.35104 13.9473C6.7442 13.139 6.41602 12.1515 6.41683 11.1363C6.41683 8.57491 8.47016 6.49612 11.0002 6.49612Z" fill="#ffff" />
          </svg> */}
          <p>© 2025 GOHAMMA. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer